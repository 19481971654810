import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useLabels } from "@/core/context/LabelsContext"
import { startImpersonatingTestUser } from "@/product/util/hook/useInitImpersonateTestUser"
import Relay from "@/relay/relayUtils"
import ProfileAvatarWithDetails, {
  ProfileAvatarWithDetailsSkeleton,
} from "@/user/common/profile-avatar-with-details/ProfileAvatarWithDetails"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import CreateTestUserButton from "@components/test-user/CreateTestUserButton"
import DeleteTestUserButton from "@components/test-user/DeleteTestUserButton"
import { TestUserDropdownContent_TestMembersQuery } from "@components/test-user/__generated__/TestUserDropdownContent_TestMembersQuery.graphql"
import {
  DiscoButton,
  DiscoButtonSkeleton,
  DiscoIcon,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import DiscoMoreActionsDropdown from "@disco-ui/dropdown/DiscoMoreActionsDropdown"
import { useTheme } from "@material-ui/core"
import classnames from "classnames"
import { graphql, useSubscribeToInvalidationState } from "react-relay"

function TestUserDropdownContent() {
  const activeOrganization = useActiveOrganization()!
  const labels = useLabels()
  const theme = useTheme()
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles({
    hideWithDisplay: { stylesWhenShown: { display: "flex" } },
  })

  const [{ node }, refetchMainUserLinks] =
    Relay.useRefetchableQuery<TestUserDropdownContent_TestMembersQuery>(
      graphql`
        query TestUserDropdownContent_TestMembersQuery($organizationMembershipId: ID!) {
          node(id: $organizationMembershipId) {
            ... on OrganizationMembership {
              id
              __typename
              mainUserLinks(first: 10)
                @connection(key: "TestUserDropdownContent__mainUserLinks") {
                __id
                edges {
                  node {
                    id
                    key
                    testUser {
                      id
                      fullName
                      ...ProfileAvatarWithDetailsFragment
                      ...DeleteTestUserButtonFragment
                    }
                  }
                }
              }
            }
          }
        }
      `,
      {
        organizationMembershipId: activeOrganization.viewerMembership!.id,
      },
      {
        fetchPolicy: "network-only",
      }
    )
  const organizationMembership = Relay.narrowNodeType(node, "OrganizationMembership")

  useSubscribeToInvalidationState(
    organizationMembership?.mainUserLinks?.__id
      ? [organizationMembership?.mainUserLinks.__id]
      : [],
    () =>
      refetchMainUserLinks({
        organizationMembershipId: activeOrganization.viewerMembership!.id,
      })
  )

  if (!organizationMembership) return null

  const userLinks = Relay.connectionToArray(organizationMembership.mainUserLinks)
  const hasUserLinks = userLinks.length > 0
  const hasMaximumTestUsers = userLinks.length >= 5

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <DiscoText variant={"body-md-600"}>{"Test Members"}</DiscoText>
        {hasUserLinks && (
          <DiscoTooltip
            content={getHelpText()}
            placement={"right"}
            data-testid={"TestUserDropdownItem.title.tooltip"}
          >
            <span style={{ display: "inherit" }}>
              <DiscoIcon
                icon={"iconsax.custom-info"}
                width={18}
                height={18}
                color={theme.palette.text.primary}
              />
            </span>
          </DiscoTooltip>
        )}
      </div>
      {hasUserLinks ? (
        userLinks.map((userLink) => {
          return (
            <DiscoDropdownItem
              key={userLink.id}
              className={classnames(
                classes.userLinkDropdownItem,
                showOnHoverClasses.hoverable
              )}
              title={
                <ProfileAvatarWithDetails
                  testid={`TestUserDropdownItem.profile-avatar.${userLink.testUser.fullName}`}
                  userKey={userLink.testUser}
                  linkToProfile={false}
                  avatarSize={32}
                  truncateName={1}
                />
              }
              rightButton={
                <span className={showOnHoverClasses.showable}>
                  <DiscoMoreActionsDropdown
                    testid={`TestUserDropdownItem.profile-avatar.${userLink.testUser.fullName}.more-actions-dropdown`}
                    dropdownProps={{
                      anchorOrigin: { vertical: "top", horizontal: "right" },
                      transformOrigin: { vertical: "top", horizontal: "left" },
                      isNested: true,
                    }}
                  >
                    <DeleteTestUserButton testUserKey={userLink.testUser}>
                      {(buttonProps) => (
                        <DiscoDropdownItem
                          {...buttonProps}
                          title={"Delete"}
                          icon={<DiscoIcon icon={"iconsax.trash"} />}
                        />
                      )}
                    </DeleteTestUserButton>
                  </DiscoMoreActionsDropdown>
                </span>
              }
              onClick={() => startImpersonatingTestUser(userLink.key)}
            />
          )
        })
      ) : (
        <DiscoText
          color={"text.secondary"}
          testid={"TestUserDropdownItem.empty-state-message"}
        >
          {getHelpText()}
        </DiscoText>
      )}
      <CreateTestUserButton>
        {(buttonProps) => {
          return (
            <DiscoTooltip
              content={"You have reached the maximum of 5 Test Members"}
              disabled={!hasMaximumTestUsers}
            >
              <DiscoButton
                {...buttonProps}
                variant={"outlined"}
                color={"grey"}
                leftIcon={"iconsax.add"}
                disabled={hasMaximumTestUsers}
              >
                {"Create Test Member"}
              </DiscoButton>
            </DiscoTooltip>
          )
        }}
      </CreateTestUserButton>
    </div>
  )

  function getHelpText() {
    return `You can have up to 5 Test Members that can be used to test the ${labels.admin_member.singular} experience in the community.`
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    minWidth: 288,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  userLinkDropdownItem: {
    padding: theme.spacing(1.5),
  },
}))

function TestUserDropdownContentSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <DiscoText variant={"body-md-600"}>{"Test Members"}</DiscoText>
      <ProfileAvatarWithDetailsSkeleton avatarSize={32} />
      <ProfileAvatarWithDetailsSkeleton avatarSize={32} />
      <ProfileAvatarWithDetailsSkeleton avatarSize={32} />
      <DiscoButtonSkeleton />
    </div>
  )
}

export default Relay.withSkeleton({
  component: TestUserDropdownContent,
  skeleton: TestUserDropdownContentSkeleton,
})
