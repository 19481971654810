import { LocationState } from "@/core/route/util/routeUtils"
import FullScreenLoadingPageWithMessage, {
  DEFAULT_FULL_SCREEN_LOADING_PAGE_REDIRECT_ROUTE,
} from "@components/loading-page/FullScreenLoadingPageWithMessage"
import { DeleteTestUserPageMutation } from "@components/test-user/__generated__/DeleteTestUserPageMutation.graphql"
import { displayErrorToast, displayToast } from "@components/toast/ToastProvider"
import { useMutation } from "react-relay"
import { useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

function DeleteTestUserPage() {
  const history = useHistory()

  const [commit] = useMutation<DeleteTestUserPageMutation>(graphql`
    mutation DeleteTestUserPageMutation($input: DeleteTestUserInput!) {
      response: deleteTestUser(input: $input) {
        errors {
          field
          message
        }
      }
    }
  `)

  return (
    <FullScreenLoadingPageWithMessage
      title={"Deleting Test Member"}
      subtitle={
        "We are deleting data associated with this test member. This process can take a while, hang in there..."
      }
      onLoad={handleSubmit}
    />
  )

  function handleSubmit(locationState: LocationState) {
    if (!locationState?.deleteTestUserPageData) {
      return history.replace(DEFAULT_FULL_SCREEN_LOADING_PAGE_REDIRECT_ROUTE)
    }

    commit({
      variables: {
        input: { testUserId: locationState.deleteTestUserPageData.testUserId },
      },
      // Invalidate the store so when we get redirected back to the original page,
      // all the data will be refetched so we can ensure everything is up to date
      updater: (store) => store.invalidateStore(),
      onCompleted({ response }) {
        if (response.errors?.length) return displayErrorToast(response.errors[0].message)

        redirect(locationState)
        displayToast({
          message: "Test member deleted",
          testid: "DeleteTestUserPage.success-toast",
        })
      },
      onError(error) {
        redirect(locationState)
        displayErrorToast(error)
      },
    })
  }

  // Send back to where they came from OR the community home
  function redirect(locationState: LocationState) {
    if (locationState?.deleteTestUserPageData) {
      history.replace(locationState.deleteTestUserPageData.from)
    } else {
      history.replace(DEFAULT_FULL_SCREEN_LOADING_PAGE_REDIRECT_ROUTE)
    }
  }
}

export default DeleteTestUserPage
