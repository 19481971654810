import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Emoji from "a11y-react-emoji"

export type ContentEmojiProps = {
  emoji: {
    native: string
    shortcodes?: string
  }
  size?: number
}

function ContentEmoji({ emoji, size = 24 }: ContentEmojiProps) {
  const classes = useStyles({ size })

  return (
    <Emoji
      symbol={emoji.native}
      label={emoji.shortcodes || "emoji"}
      className={`${classes.emoji} ${classes.size}`}
    />
  )
}

const useStyles = makeUseStyles(() => ({
  emoji: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 1,
    verticalAlign: "middle",
  },
  size: {
    fontSize: (props: { size: number }) => `${props.size}px`,
  },
}))

export default ContentEmoji
