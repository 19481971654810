/**
 * @generated SignedSource<<dfd36b561e45ecc5f7217481248b9f5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SSOLoginGetSSOLoginRedirectUrlMutation$variables = Record<PropertyKey, never>;
export type SSOLoginGetSSOLoginRedirectUrlMutation$data = {
  readonly getSSOLoginRedirectUrl: {
    readonly data: string | null;
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
  };
};
export type SSOLoginGetSSOLoginRedirectUrlMutation = {
  response: SSOLoginGetSSOLoginRedirectUrlMutation$data;
  variables: SSOLoginGetSSOLoginRedirectUrlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GetSSOLoginRedirectUrlResponse",
    "kind": "LinkedField",
    "name": "getSSOLoginRedirectUrl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SSOLoginGetSSOLoginRedirectUrlMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SSOLoginGetSSOLoginRedirectUrlMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "bc3499abb52c44aa4fe5e0b744d75f0d",
    "id": null,
    "metadata": {},
    "name": "SSOLoginGetSSOLoginRedirectUrlMutation",
    "operationKind": "mutation",
    "text": "mutation SSOLoginGetSSOLoginRedirectUrlMutation {\n  getSSOLoginRedirectUrl {\n    data\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d5b6adc9bc92a43e36a614455933ddda";

export default node;
