/**
 * @generated SignedSource<<c4a666dc69259dba594410729a66ae80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GlobalAddDropdownQuery$variables = {
  membershipId: string;
};
export type GlobalAddDropdownQuery$data = {
  readonly membership: {
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"useViewerCanCreateEventsFragment">;
  } | null;
};
export type GlobalAddDropdownQuery = {
  response: GlobalAddDropdownQuery$data;
  variables: GlobalAddDropdownQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "membershipId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "membershipId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalAddDropdownQuery",
    "selections": [
      {
        "alias": "membership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useViewerCanCreateEventsFragment"
              }
            ],
            "type": "OrganizationMembership",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GlobalAddDropdownQuery",
    "selections": [
      {
        "alias": "membership",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "adminMembershipsWithEventApps",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "appKind",
                    "value": "events"
                  },
                  {
                    "kind": "Literal",
                    "name": "productType",
                    "value": "course"
                  },
                  {
                    "kind": "Literal",
                    "name": "roles",
                    "value": [
                      "manager",
                      "instructor"
                    ]
                  }
                ],
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "productMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  }
                ],
                "storageKey": "productMemberships(appKind:\"events\",productType:\"course\",roles:[\"manager\",\"instructor\"])"
              }
            ],
            "type": "OrganizationMembership",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "000777b7906a9cdccacfe9340576bb14",
    "id": null,
    "metadata": {},
    "name": "GlobalAddDropdownQuery",
    "operationKind": "query",
    "text": "query GlobalAddDropdownQuery(\n  $membershipId: ID!\n) {\n  membership: node(id: $membershipId) {\n    __typename\n    ... on OrganizationMembership {\n      id\n      ...useViewerCanCreateEventsFragment\n    }\n    id\n  }\n}\n\nfragment useViewerCanCreateEventsFragment on OrganizationMembership {\n  adminMembershipsWithEventApps: productMemberships(roles: [manager, instructor], productType: \"course\", appKind: events) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "c7d38e50898eac2680c3c77b3aea70b3";

export default node;
