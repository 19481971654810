import makeUseStyles from "@assets/style/util/makeUseStyles"
import TestUserDropdownContent from "@components/test-user/TestUserDropdownContent"
import { DiscoDropdown, DiscoDropdownProps } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"

interface Props {
  children: DiscoDropdownProps["menuButton"]
}

function TestUserDropdown({ children }: Props) {
  const isMobile = useIsMobile()
  const classes = useStyles()

  return (
    <DiscoDropdown
      menuButton={children}
      menuClasses={{ paper: classes.dropdownPopoverPaper }}
      // On mobile, position the dropdown right below the parent dropdown item
      transformOrigin={
        isMobile
          ? { vertical: "top", horizontal: "center" }
          : { vertical: "bottom", horizontal: "left" }
      }
      anchorOrigin={
        isMobile
          ? {
              vertical: "bottom",
              horizontal: "center",
            }
          : {
              vertical: "top",
              horizontal: "right",
            }
      }
      isNested
    >
      <TestUserDropdownContent />
    </DiscoDropdown>
  )
}

const useStyles = makeUseStyles({
  dropdownPopoverPaper: {
    width: "100%",
  },
})

export default TestUserDropdown
