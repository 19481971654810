import { updateRelayContentReaction } from "@/content/util/contentUtils"
import { useFormStore } from "@/core/form/store/FormStore"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { EmojiObject } from "@components/chat/channel/emoji-button/ChatEmoji"
import ContentEmoji from "@components/reactions/ContentEmoji"
import ContentEmojiButtonTooltip from "@components/reactions/ContentEmojiButtonTooltip"
import { ContentEmojiButtonMutation } from "@components/reactions/__generated__/ContentEmojiButtonMutation.graphql"
import { ContentEmojiButtonTooltipPreloadedQuery } from "@components/reactions/__generated__/ContentEmojiButtonTooltipPreloadedQuery.graphql"
import { ContentEmojiButton_ContentReactionFragment$key } from "@components/reactions/__generated__/ContentEmojiButton_ContentReactionFragment.graphql"
import {
  DiscoButton,
  DiscoButtonSize,
  DiscoButtonSkeleton,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import data from "@emoji-mart/data"
import { darken, lighten } from "@material-ui/core"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { getEmojiDataFromNative, init } from "emoji-mart"
import React, { Suspense, useCallback, useEffect, useState } from "react"
import { graphql, useFragment, useQueryLoader } from "react-relay"
import { useLongPress } from "use-long-press"

type ContentEmojiButtonProps = {
  contentKey: ContentEmojiButton_ContentReactionFragment$key
  contentId: GlobalID
  contentUsageId?: GlobalID
  size?: DiscoButtonSize
}

export const ContentEmojiButtonPreloadedQuery = graphql`
  query ContentEmojiButtonTooltipPreloadedQuery($id: ID!, $emojiCode: String!) {
    node(id: $id) {
      __typename
      ... on Content {
        reactions(emojiCode: $emojiCode, first: 5) {
          edges {
            node {
              id
              ...ContentEmojiButtonTooltipFragment @relay(mask: false)
            }
          }
        }
      }
    }
  }
`

// Initialize emoji-mart
init({ data })

function ContentEmojiButton(props: ContentEmojiButtonProps) {
  const { contentId, contentKey, contentUsageId, size = "medium" } = props
  const isWebView = useIsWebView()
  const isMobile = useIsMobile()
  const [showToolTip, setShowToolTip] = useState(false)

  const [queryReference, loadQuery] =
    useQueryLoader<ContentEmojiButtonTooltipPreloadedQuery>(
      ContentEmojiButtonPreloadedQuery
    )

  const content = useFragment<ContentEmojiButton_ContentReactionFragment$key>(
    graphql`
      fragment ContentEmojiButton_ContentReactionFragment on ContentReaction {
        id
        hasViewerReacted
        emojiCode
        emojiAlias
        emojiCount
      }
    `,
    contentKey || null
  )
  const { emojiAlias, emojiCode } = content
  const reactionCount = content.emojiCount
  const { hasViewerReacted } = content
  const [emojiData, setEmojiData] = useState<EmojiObject | null>(null)

  useEffect(() => {
    if (!emojiCode) return

    async function loadEmoji() {
      try {
        const emojiDataObject = await getEmojiDataFromNative(emojiCode)
        setEmojiData(emojiDataObject)
      } catch (error) {
        console.error("Error loading emoji:", error)
      }
    }
    loadEmoji()
  }, [emojiCode])

  const classes = useStyles({ hasViewerReacted, reactionCount })

  const form = useFormStore<ContentEmojiButtonMutation>(
    graphql`
      mutation ContentEmojiButtonMutation($input: UpdateContentLikeInput!) {
        response: updateContentLike(input: $input) {
          node {
            id
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      contentId,
      contentUsageId,
      emojiCode,
      emojiAlias,
    }
  )

  function loadPreloadedQuery() {
    if (queryReference) return
    loadQuery({ id: contentId, emojiCode })
  }

  const callback = useCallback(() => {
    setShowToolTip(true)
  }, [])

  const bind = useLongPress(isWebView || isMobile ? callback : null, {
    onStart: () => {
      loadPreloadedQuery()
    },
    onFinish: () => {
      setShowToolTip(false)
    },
    onCancel: () => {
      setShowToolTip(false)
    },
    filterEvents: () => true,
    threshold: 400,
    captureEvent: true,
    cancelOnMovement: 25, // Square side size (in pixels) inside which movement won't cancel long press
    cancelOutsideElement: true, // Cancel long press when moved mouse / pointer outside element while pressing
  })

  const discoButton = (
    <div className={classes.buttonWrapper}>
      <div className={classes.nonSelectable}>
        <DiscoButton
          testid={`ContentEmojiButton-${emojiCode}`}
          classes={{
            root: classes.button,
            leftIcon: classes.leftIcon,
          }}
          color={"transparent"}
          borderRadius={size === "xsmall" ? 20 : undefined}
          size={size}
          leftIcon={emojiData ? <ContentEmoji emoji={emojiData} size={16} /> : undefined}
          onClick={handleEmojiClick}
          onMouseOver={() => loadPreloadedQuery()}
          onTouchStart={() => loadPreloadedQuery()}
          {...bind()}
        >
          {reactionCount && reactionCount > 0 && (
            <DiscoText className={classes.reactionCount} variant={"body-sm"}>
              {reactionCount}
            </DiscoText>
          )}
        </DiscoButton>
      </div>
    </div>
  )

  const openTooltipOnHover = !isMobile && !isWebView

  return (
    <Suspense fallback={discoButton}>
      <DiscoTooltip
        disabled={openTooltipOnHover && !queryReference}
        open={openTooltipOnHover ? undefined : showToolTip}
        content={
          <>
            {queryReference && emojiData ? (
              <ContentEmojiButtonTooltip
                emoji={emojiData}
                occurrenceQueryRef={queryReference}
                hasViewerReacted={hasViewerReacted}
                reactionCount={reactionCount}
              />
            ) : null}
          </>
        }
      >
        {discoButton}
      </DiscoTooltip>
    </Suspense>
  )

  async function handleEmojiClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation() // prevent post drawer from opening on emoji select
    await form.submit(form.state, {
      updater: (store) => {
        updateRelayContentReaction(store, {
          contentId,
          emojiCode,
          contentUsageId,
        })
      },
      optimisticUpdater: (store) => {
        updateRelayContentReaction(store, {
          contentId,
          emojiCode,
          contentUsageId,
        })
      },
    })
  }
}

interface StyleProps {
  hasViewerReacted?: boolean
  reactionCount?: number
}

const useStyles = makeUseStyles((theme) => ({
  reactionCount: (props: StyleProps) => ({
    display: "inline-block",
    color: props.hasViewerReacted
      ? theme.palette.type === "light"
        ? lighten(theme.palette.primary.main, 0.2)
        : theme.palette.common.white
      : "currentColor",

    fontWeight: 500,
  }),
  button: {
    backgroundColor: "transparent",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  skeletonButton: {
    width: "54px",
    height: "24px",
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("sm")]: {
      height: "36px",
    },
  },
  buttonWrapper: {
    all: "unset",
    width: "51px",
    height: "28px",
    boxSizing: "border-box",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.xl,
    border: (props: StyleProps) => {
      if (props.hasViewerReacted) {
        return `1.5px solid ${lighten(theme.palette.primary.main, 0.2)}`
      }
      return "1.5px solid transparent"
    },
    backgroundColor: (props: StyleProps) => {
      if (props.hasViewerReacted) {
        return theme.palette.type === "light"
          ? lighten(theme.palette.primary.main, 0.9)
          : darken(theme.palette.primary.main, 0.6)
      }
      return theme.palette.groovy.neutral[100]
    },
    cursor: "pointer",
    "&:hover": {
      backgroundColor: (props: StyleProps) => {
        if (props.hasViewerReacted) {
          return theme.palette.type === "light"
            ? lighten(theme.palette.primary.main, 0.7)
            : darken(theme.palette.primary.main, 0.3)
        }
        return theme.palette.groovy.neutral[200]
      },
    },
  },
  leftIcon: (props: StyleProps) => ({
    height: 18,
    marginRight: props.reactionCount ? theme.spacing(0.5) : 0,
    marginBottom: theme.spacing(0),
    display: "flex",
    alignItems: "center",
  }),
  nonSelectable: {
    width: "100%",
    WebkitTouchCallout: "none !important",
    WebkitUserSelect: "none !important",
    KhtmlUserSelect: "none !important",
    MozUserSelect: "none !important",
    MsUserSelect: "none !important",
    userSelect: "none !important",
  },
}))

function ContentEmojiButtonSkeleton() {
  const classes = useStyles()
  return <DiscoButtonSkeleton className={classes.skeletonButton} />
}

export default Relay.withSkeleton({
  component: ContentEmojiButton,
  skeleton: ContentEmojiButtonSkeleton,
})
