import ROUTE_NAMES from "@/core/route/util/routeNames"
import { LocationState } from "@/core/route/util/routeUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoSection, DiscoSpinner, DiscoText } from "@disco-ui"
import { useEffect } from "react"
import ReactDOM from "react-dom"
import { useHistory, useLocation } from "react-router-dom"

export const DEFAULT_FULL_SCREEN_LOADING_PAGE_REDIRECT_ROUTE =
  ROUTE_NAMES.COMMUNITY.HOME.ROOT

interface FullScreenLoadingPageWithMessageProps {
  title: string
  subtitle: string
  onLoad: (locationState: LocationState) => void
}

function FullScreenLoadingPageWithMessage({
  title,
  subtitle,
  onLoad,
}: FullScreenLoadingPageWithMessageProps) {
  const modalRoot = document.getElementById("modal-root")!
  const classes = useStyles()
  const location = useLocation<LocationState>()
  const history = useHistory()

  useEffect(() => {
    if (!location.state) {
      history.replace(DEFAULT_FULL_SCREEN_LOADING_PAGE_REDIRECT_ROUTE)
      return
    }

    onLoad(location.state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return ReactDOM.createPortal(
    <div className={classes.container}>
      <DiscoSection className={classes.section}>
        <DiscoSpinner />
        <div className={classes.textContainer}>
          <DiscoText variant={"body-lg-600"}>{title}</DiscoText>
          <DiscoText color={"text.secondary"}>{subtitle}</DiscoText>
        </div>
      </DiscoSection>
    </div>,
    modalRoot
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.default,
    zIndex: 100,
  },
  section: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(2),
    maxWidth: "480px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(1),
    textAlign: "center",
  },
}))

export default FullScreenLoadingPageWithMessage
