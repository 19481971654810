import { useLabels } from "@/core/context/LabelsContext"
import FormStore from "@/core/form/store/FormStore"
import MemberGroupsMultiSelect from "@/product/common/member-group/modal/components/MemberGroupsMultiSelect"
import ProductRoleSelect from "@/product/common/ProductRoleSelect"
import ProductWithDetails from "@/product/common/ProductWithDetails"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import {
  CreateTestUserInput,
  ProductRole,
} from "@components/test-user/__generated__/CreateTestUserModalMutation.graphql"
import { TestUserFormFieldsProductCardQuery } from "@components/test-user/__generated__/TestUserFormFieldsProductCardQuery.graphql"
import {
  DiscoDivider,
  DiscoFormControl,
  DiscoIcon,
  DiscoIconButton,
  DiscoSection,
  DiscoText,
} from "@disco-ui"
import { observable } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { graphql, useLazyLoadQuery } from "react-relay"

interface Props {
  productId: GlobalID
  form: FormStore<CreateTestUserInput>
}

function TestUserFormFieldsProductCard({ productId, form }: Props) {
  const classes = useStyles()
  const labels = useLabels()

  const { node } = useLazyLoadQuery<TestUserFormFieldsProductCardQuery>(
    graphql`
      query TestUserFormFieldsProductCardQuery($id: ID!) {
        node(id: $id) {
          ... on Product {
            __typename
            id
            name
            status
            ...ProductWithDetailsFragment
            ...MemberGroupsMultiSelect_ProductFragment
          }
        }
      }
    `,
    { id: productId }
  )

  const product = Relay.narrowNodeType(node, "Product")
  const productFromForm = form.state.products?.find((p) => p.productId === product?.id)

  useEffect(() => {
    if (!product) return
    if (!productFromForm) return

    if (product.status === "draft") {
      productFromForm.role = "instructor"
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!product) return null

  if (!productFromForm) return null

  return (
    <DiscoSection
      testid={`TestUserFormFieldsProductCard.${product.name}`}
      groovyDepths={"insideCard"}
      padding={2}
      className={classes.container}
    >
      <div className={classes.header}>
        <ProductWithDetails
          productKey={product}
          testid={`TestUserFormFieldsProductCard.${product.name}`}
        />

        <DiscoIconButton
          testid={`TestUserFormFieldsProductCard.${product.name}.remove`}
          onClick={handleRemoveProduct}
          width={40}
          height={40}
        >
          <DiscoIcon icon={"close"} />
        </DiscoIconButton>
      </div>

      <DiscoDivider />

      <DiscoFormControl
        label={
          <DiscoText
            color={"text.secondary"}
          >{`${labels.admin_experience.singular} Role`}</DiscoText>
        }
        variant={"two-column"}
      >
        <ProductRoleSelect
          testid={`TestUserFormFieldsProductCard.${product.name}.ProductRoleSelect`}
          value={productFromForm.role}
          onChange={handleRoleSelect}
          disabledValues={
            product.status === "draft"
              ? [
                  {
                    role: "member",
                    tooltipContent: `Can not add member to ${labels.admin_experience.singular} still in 'draft' mode`,
                  },
                ]
              : undefined
          }
          fullWidth
        />
      </DiscoFormControl>

      <DiscoFormControl
        label={
          <DiscoText
            color={"text.secondary"}
          >{`Add to ${labels.admin_experience.singular} groups (Optional)`}</DiscoText>
        }
        variant={"two-column"}
      >
        <MemberGroupsMultiSelect
          testid={`TestUserFormFieldsProductCard.${product.name}.member-groups`}
          selectedGroupIds={
            productFromForm.memberGroupIds ? productFromForm.memberGroupIds.slice() : []
          }
          setSelectedGroupIds={(ids: GlobalID[]) =>
            productFromForm.memberGroupIds
              ? productFromForm.memberGroupIds.replace(ids)
              : (productFromForm.memberGroupIds = observable.array(ids))
          }
          productKey={product}
          showGroupKinds={["custom"]}
        />
      </DiscoFormControl>
    </DiscoSection>
  )

  function handleRemoveProduct() {
    const item = form.state.products?.find((p) => p.productId === product?.id)
    if (!item) return
    form.state.products?.remove(item)
  }

  function handleRoleSelect(role: ProductRole | null) {
    if (!productFromForm) return
    if (!role) return
    productFromForm.role = role
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}))

export default Relay.withSkeleton({
  component: observer(TestUserFormFieldsProductCard),
  skeleton: () => null,
})
