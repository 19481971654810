import Badge from "@/admin/experiences/badges/Badge"
import { ContentUsageUtils } from "@/content-usage/ContentUsageUtils"
import ContentThumbnail from "@/content/detail/ContentThumbnail"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import MemberGroupTag from "@/product/common/member-group/common/tag/MemberGroupTag"
import useIsWebView from "@/product/util/hook/useIsWebView"
import { Connection, GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DaySquare from "@components/date/day-square/DaySquare"
import { useEditorMentions } from "@components/editor/plugins/mentions/EditorMentionsProvider"
import { $createMentionNode } from "@components/editor/plugins/mentions/MentionNode"
import { MentionsPluginQuery } from "@components/editor/plugins/mentions/__generated__/MentionsPluginQuery.graphql"
import { MentionsPlugin_Mentionable$data } from "@components/editor/plugins/mentions/__generated__/MentionsPlugin_Mentionable.graphql"
import { DiscoIcon, DiscoText, DiscoTooltip } from "@disco-ui"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
  useBasicTypeaheadTriggerMatch,
} from "@lexical/react/LexicalTypeaheadMenuPlugin"
import { Skeleton } from "@material-ui/lab"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import useDebounce from "@utils/hook/useDebounce"
import classNames from "classnames"
import { $getSelection, TextNode } from "lexical"
import pluralize from "pluralize"
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { graphql } from "relay-runtime"
import { v4 as uuidv4 } from "uuid"

const BLOCK_MENTION_TYPES: string[] = ["Products", "Events", "Content"]

export enum MENTION_TRIGER {
  user = "@",
  content = "@@",
  event = "@@@",
  product = "@@@@",
}

const PUNCTUATION = "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;"
const NAME = `\\b[A-Z][^\\s${PUNCTUATION}]`

const DocumentMentionsRegex = { NAME, PUNCTUATION }

const CapitalizedNameMentionsRegex = new RegExp(
  `(^|[^#])((?:${DocumentMentionsRegex.NAME}{" + 1 + ",})$)`
)

type TriggerTextResult = {
  trigger: string | null
  text: string | null
}
function findTriggerAndText(input: string): TriggerTextResult {
  const regex = /(^|\s)(@{1,4})(?!@|\s)(\w*|$)/g
  let result: TriggerTextResult = { trigger: null, text: null }
  let match

  while ((match = regex.exec(input)) !== null) {
    if (match[2] && (match[3] || input.slice(match.index).trim().endsWith(match[2]))) {
      result = { trigger: match[2], text: match[3] }
      break // Break out after the first valid match
    }
  }

  return result
}

// At most, 5 suggestions are shown in the popup.
const SUGGESTION_LIST_LENGTH_LIMIT = 15

type MentionableData = MentionsPlugin_Mentionable$data

function useMentionLookupService(
  mentionString: string | null,
  menuFilter: MenuFilter,
  hasAttachBlockMention: boolean,
  hasUserMention: boolean
) {
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()

  const searchProducts = hasAttachBlockMention && menuFilter === "Products"
  const searchEvents = hasAttachBlockMention && menuFilter === "Events"
  const searchContent = hasAttachBlockMention && menuFilter === "Content"
  const searchUsers = hasUserMention && menuFilter === "UsersAndGroups"

  const [{ product, organization }, refetchSearch, isLoading] =
    Relay.useRefetchableQuery<MentionsPluginQuery>(
      graphql`
        query MentionsPluginQuery(
          $productId: ID!
          $organizationId: ID!
          $search: String
          $searchProducts: Boolean!
          $searchEvents: Boolean!
          $searchContent: Boolean!
          $searchUsers: Boolean!
          $includeOrg: Boolean!
        ) {
          product: node(id: $productId) @include(if: $searchUsers) {
            ... on Product {
              mentionables(first: 20, search: $search) {
                edges {
                  node {
                    ...MentionsPlugin_Mentionable @relay(mask: false)
                  }
                }
              }
            }
          }
          organization: node(id: $organizationId) @include(if: $includeOrg) {
            ... on Organization {
              mentionables(first: 20, search: $search) @include(if: $searchUsers) {
                edges {
                  node {
                    ...MentionsPlugin_Mentionable @relay(mask: false)
                  }
                }
              }
              occurrences(first: 20, search: $search) @include(if: $searchEvents) {
                edges {
                  node {
                    ...MentionsPlugin_Mentionable @relay(mask: false)
                  }
                }
              }
              products(first: 20, search: $search, types: [course, pathway])
                @include(if: $searchProducts) {
                edges {
                  node {
                    ...MentionsPlugin_Mentionable @relay(mask: false)
                  }
                }
              }
              contentUsages(first: 20, search: $search) @include(if: $searchContent) {
                edges {
                  node {
                    id
                    ...MentionsPlugin_Mentionable @relay(mask: false)
                  }
                }
              }
            }
          }
        }
      `,
      {
        productId: activeProduct ? activeProduct.id : "",
        organizationId: activeOrganization.id,
        search: mentionString ?? "",
        searchProducts,
        searchEvents,
        searchContent,
        includeOrg: searchUsers || searchEvents || searchContent || searchProducts,
        searchUsers,
      },
      { refetchInBackground: true }
    )

  const debouncedRefetchSearch = useDebounce((newSearch: string) => {
    refetchSearch({
      productId: activeProduct ? activeProduct.id : "",
      organizationId: activeOrganization.id,
      search: newSearch,
      searchProducts,
      searchEvents,
      searchContent,
      includeOrg: searchUsers || searchEvents || searchContent || searchProducts,
      searchUsers,
    })
  }, 100)

  useEffect(() => {
    if (!menuFilter) return
    debouncedRefetchSearch(mentionString ?? "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuFilter, mentionString])

  let source
  switch (menuFilter) {
    case "Products":
      source = organization?.products as Connection<MentionableData>
      break
    case "Events":
      source = organization?.occurrences as Connection<MentionableData>
      break
    case "Content":
      source = organization?.contentUsages as Connection<MentionableData>
      break
    case "UsersAndGroups":
      source = activeProduct
        ? (product?.mentionables as Connection<MentionableData>)
        : (organization?.mentionables as Connection<MentionableData>)
      break
  }
  const mentionables = Relay.connectionToArray<MentionableData, MentionableData>(source)

  return { mentionables, isLoading }
}

function checkForCapitalizedNameMentions(
  text: string,
  minMatchLength: number
): MenuTextMatch | null {
  const match = CapitalizedNameMentionsRegex.exec(text)
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1]

    const matchingString = match[2]
    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString,
      }
    }
  }
  return null
}

type MenuFilter = "Products" | "Events" | "Content" | "UsersAndGroups" | null

type MentionableType =
  | "user"
  | "memberGroup"
  | "mentionedProduct"
  | "mentionedContentUsage"
  | "mentionedOccurrence"

class MentionTypeaheadOption extends MenuOption {
  name: string
  type: MentionableType
  mentionable: MentionsPlugin_Mentionable$data
  mentionableId: GlobalID
  children: JSX.Element
  disabled: boolean
  disabledReason?: string

  constructor(opts: {
    name: string
    type: MentionableType
    mentionable: MentionsPlugin_Mentionable$data
    mentionableId: GlobalID
    children: JSX.Element
    disabled?: boolean
    disabledReason?: string
  }) {
    super(opts.mentionableId)
    this.name = opts.name
    this.type = opts.type
    this.mentionable = opts.mentionable
    this.mentionableId = opts.mentionableId
    this.children = opts.children
    this.disabled = opts.disabled ?? false
    this.disabledReason = opts.disabledReason
  }

  get displayType() {
    switch (this.type) {
      case "memberGroup":
        return "group"
      case "user":
      default:
        return this.type
    }
  }
}

function MentionsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number
  isSelected: boolean
  onClick: () => void
  onMouseEnter: () => void
  option: MentionTypeaheadOption
}) {
  const classes = useStyles({ isSelected, disabled: option.disabled })

  return (
    <DiscoTooltip
      title={
        <div className={classes.tooltipTitle}>
          <DiscoIcon icon={"eye-off"} height={16} width={16} color={"inherit"} />
          <DiscoText variant={"body-xs-600"}>
            {`You can't @mention this ${option.displayType}.`}
          </DiscoText>
        </div>
      }
      content={<DiscoText variant={"body-xs"}>{option.disabledReason}</DiscoText>}
      disabled={!option.disabled}
    >
      <li
        key={option.key}
        ref={option.setRefElement}
        data-testid={"MentionSuggestionList.item"}
        id={`typeahead-item-${index}`}
        tabIndex={-1}
        className={classes.item}
        role={"option"}
        onKeyDown={handleKeyDown}
        aria-selected={isSelected}
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        aria-disabled={option.disabled}
      >
        {option.children}
      </li>
    </DiscoTooltip>
  )

  function handleKeyDown(e: React.KeyboardEvent<HTMLLIElement>) {
    if (!onClick || (e.key !== "Enter" && e.key !== " ")) return
    option.ref?.current?.click()
  }
}

type StyleProps = {
  isSelected: boolean
  disabled: boolean
}

const useStyles = makeUseStyles((theme) => ({
  item: ({ disabled, isSelected }: StyleProps) => ({
    cursor: disabled ? "not-allowed" : "pointer",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    borderRadius: theme.measure.borderRadius.default,
    padding: theme.spacing(0.5),
    margin: theme.spacing(0, 0.5),
    opacity: disabled ? 0.5 : 1,
    background: isSelected
      ? theme.palette.groovy.neutral[100]
      : theme.palette.background.paper,
  }),
  tooltipTitle: {
    display: "flex",
    gap: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}))

type MentionsPluginProps = {
  hasUserMention: boolean
  hasAttachBlockMention: boolean
}

export default function MentionsPlugin(props: MentionsPluginProps): JSX.Element | null {
  const { hasUserMention, hasAttachBlockMention } = props
  const experienceLabel = useLabel("experience")
  const pathwayLabel = useLabel("pathway")

  const isMobile = useIsMobile()
  const isWebView = useIsWebView()

  const [editor] = useLexicalComposerContext()
  const { setMentions } = useEditorMentions()

  const [menuFilter, setMenuFilter] = useState<MenuFilter>(null)

  // Default to have the mentions menu raised if we are in a webview
  const [raiseMenuHeight, setRaiseMenuHeight] = useState(isWebView)
  const [hasSearched, setHasSearched] = useState(false)

  const activeProduct = useActiveProduct()
  const memberLabel = useLabel(activeProduct ? "product_member" : "organization_member")

  const [queryString, setQueryString] = useState<string | null>(null)

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch("/", {
    minLength: 0,
  })

  const checkForMentionsTrigger = useCallback(
    (text: string, minMatchLength: number): MenuTextMatch | null => {
      const newMatch = findTriggerAndText(text)

      if (newMatch.trigger !== null) {
        if (!hasUserMention && !hasAttachBlockMention) {
          setMenuFilter(null)
        }
        switch (newMatch.trigger) {
          case MENTION_TRIGER.user:
            if (!hasUserMention) break
            setMenuFilter("UsersAndGroups")
            break
          case MENTION_TRIGER.content:
            if (!hasAttachBlockMention) break
            setMenuFilter("Content")
            break
          case MENTION_TRIGER.event:
            if (!hasAttachBlockMention) break
            setMenuFilter("Events")
            break
          case MENTION_TRIGER.product:
            if (!hasAttachBlockMention) break
            setMenuFilter("Products")
            break
        }

        const matchingString = newMatch.text || ""
        const fullmatch = newMatch.trigger + matchingString

        if (matchingString.length >= minMatchLength) {
          return {
            leadOffset: (newMatch.trigger + newMatch.text).length,
            matchingString,
            replaceableString: fullmatch,
          }
        }
      }
      return null
    },
    [hasAttachBlockMention, hasUserMention]
  )

  const classes = usePopoverStyles({ isWebView })

  const { mentionables, isLoading } = useMentionLookupService(
    queryString,
    menuFilter,
    hasAttachBlockMention,
    hasUserMention
  )

  useEffect(() => {
    if (hasSearched) return
    if (!isLoading) return
    // Once the search has begun loading we can assume the user has searched
    setHasSearched(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  const options = useMemo(() => {
    const allMentionables = mentionables
      .map((mentionable) => {
        if (Relay.isNodeType(mentionable, "User")) {
          return new MentionTypeaheadOption({
            name: mentionable.fullName,
            type: "user",
            mentionable,
            mentionableId: mentionable.id,
            children: (
              <>
                <ProfileAvatar userKey={mentionable} size={24} />
                <DiscoText variant={"body-sm"}>{mentionable.fullName}</DiscoText>
              </>
            ),
          })
        }
        if (Relay.isNodeType(mentionable, "MemberGroup")) {
          const members = mentionable.memberGroupMemberships?.totalCount || 0
          return new MentionTypeaheadOption({
            name: mentionable.name,
            type: "memberGroup",
            mentionable,
            mentionableId: mentionable.id,
            children: (
              <>
                <MemberGroupTag memberGroupKey={mentionable} />
                <DiscoText
                  component={"span"}
                  variant={"body-xs-500"}
                  marginLeft={1}
                  color={"text.secondary"}
                >
                  {`(${members} ${pluralize("member", members)})`}
                </DiscoText>
              </>
            ),
            disabled: mentionable.visibility !== "everyone",
            disabledReason: `This group is only visible to admins. ${memberLabel.plural} don't know if they are added to this group.`,
          })
        }
        if (Relay.isNodeType(mentionable, "Product")) {
          return new MentionTypeaheadOption({
            name: mentionable.name,
            type: "mentionedProduct",
            mentionable,
            mentionableId: mentionable.id,
            children: (
              <>
                <Badge badgeKey={mentionable.badge} size={24} />
                <DiscoText variant={"body-sm"}>{mentionable.name}</DiscoText>
              </>
            ),
          })
        }
        if (Relay.isNodeType(mentionable, "Occurrence")) {
          const date = new Date(mentionable.datetimeRange[0])
          return new MentionTypeaheadOption({
            name: mentionable.occurrenceName!,
            type: "mentionedOccurrence",
            mentionable,
            mentionableId: mentionable.id,
            children: (
              <>
                <DaySquare
                  testid={"mentions-list-occurrence-day"}
                  date={date}
                  customClassName={classes.daySquare}
                  customSquareMonthClassName={classes.squareMonthClassName}
                  customSquareDayClassName={classes.squareDayClassName}
                />
                <div className={classes.textContainer}>
                  <DiscoText
                    variant={"body-xs-700"}
                    noWrap
                    truncateText={1}
                    className={classes.text}
                  >
                    {mentionable.occurrenceName}
                  </DiscoText>
                  {mentionable.occurrenceProduct?.type === "course" && (
                    <DiscoText
                      variant={"body-xs"}
                      noWrap
                      truncateText={1}
                      className={classes.text}
                    >
                      {`Hosted in `}
                      <DiscoText
                        component={"span"}
                        variant={"body-xs-500"}
                        color={"primary.main"}
                      >
                        {mentionable.occurrenceProduct.occurrenceProductName}
                      </DiscoText>
                    </DiscoText>
                  )}
                </div>
              </>
            ),
          })
        }
        if (Relay.isNodeType(mentionable, "ContentUsage")) {
          const permissions = mentionable.viewerPermissions
          if (!permissions.includes("content.read")) {
            return null
          }
          const locationLabel = ContentUsageUtils.contentUsageLocationLabel(mentionable)
          return new MentionTypeaheadOption({
            name: mentionable.content.name!,
            type: "mentionedContentUsage",
            mentionable,
            mentionableId: mentionable.id,
            children: (
              <>
                <div className={classes.customImageContainer}>
                  <ContentThumbnail contentKey={mentionable.content} />
                </div>
                <div className={classes.textContainer}>
                  <DiscoText
                    variant={"body-xs-700"}
                    noWrap
                    truncateText={1}
                    className={classes.text}
                  >
                    {mentionable.content.name}
                  </DiscoText>

                  <DiscoText
                    variant={"body-xs"}
                    noWrap
                    truncateText={1}
                    className={classes.text}
                  >
                    {locationLabel}
                  </DiscoText>
                </div>
              </>
            ),
          })
        }
        return null
      })
      .filter((option) => option !== null)
      .slice(0, SUGGESTION_LIST_LENGTH_LIMIT) as MentionTypeaheadOption[]

    return [
      {
        title: memberLabel.plural,
        mentionables: allMentionables.filter((option) => option.type === "user"),
      },
      {
        title: "Groups",
        mentionables: allMentionables.filter((option) => option.type === "memberGroup"),
      },
      {
        title: "Products",
        mentionables: allMentionables.filter(
          (option) => option.type === "mentionedProduct"
        ),
      },
      {
        title: "Events",
        mentionables: allMentionables.filter(
          (option) => option.type === "mentionedOccurrence"
        ),
      },
      {
        title: "Content",
        mentionables: allMentionables.filter(
          (option) => option.type === "mentionedContentUsage"
        ),
      },
    ]
  }, [mentionables, memberLabel.plural, classes])

  const onSelectOption = useCallback(
    (
      selectedOption: MentionTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void
    ) => {
      editor.update(() => {
        const { mentionable, disabled } = selectedOption
        if (disabled) return
        const mentionId = Relay.toGlobalId("ContentMention", uuidv4())

        // Add the new mention to local state so that the name is inserted by the mention component
        if (Relay.isNodeType(mentionable, "User")) {
          setMentions((prev) => [
            ...prev,
            {
              id: mentionId,
              user: mentionable,
            },
          ])
        } else if (Relay.isNodeType(mentionable, "MemberGroup")) {
          setMentions((prev) => [
            ...prev,
            {
              id: mentionId,
              memberGroup: mentionable,
            },
          ])
        } else if (Relay.isNodeType(mentionable, "Product")) {
          setMentions((prev) => [
            ...prev,
            {
              id: mentionId,
              mentionedProduct: mentionable,
            },
          ])
        } else if (Relay.isNodeType(mentionable, "Occurrence")) {
          setMentions((prev) => [
            ...prev,
            {
              id: mentionId,
              mentionedOccurrence: {
                id: mentionable.id,
                name: mentionable.occurrenceName,
                content: {
                  name: mentionable.occurrenceProduct?.occurrenceProductName,
                },
              },
            },
          ])
        } else if (Relay.isNodeType(mentionable, "ContentUsage")) {
          setMentions((prev) => [
            ...prev,
            {
              id: mentionId,
              mentionedContentUsage: {
                ...mentionable,
                content: {
                  ...mentionable.content,
                  name: mentionable.content?.name,
                },
              },
            },
          ])
        }
        const mentionNode = $createMentionNode(mentionId, selectedOption.mentionableId)
        if (nodeToReplace) nodeToReplace.replace(mentionNode)

        // Insert a space after the mention
        const selection = $getSelection()
        if (selection) selection.insertText(" ")

        closeMenu()
      })
    },
    [editor, setMentions]
  )

  const getPossibleQueryMatch = useCallback(
    (text: string): MenuTextMatch | null => {
      const match = checkForMentionsTrigger(text, 0)
      return match === null ? checkForCapitalizedNameMentions(text, 3) : match
    },
    [checkForMentionsTrigger]
  )

  const checkForMentionMatch = useCallback(
    (text: string) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor)
      // If we have a slash match, we don't want to match a mention
      if (slashMatch !== null) return null

      return getPossibleQueryMatch(text)
    },
    [checkForSlashTriggerMatch, editor, getPossibleQueryMatch]
  )

  const filteredOptions = options.flatMap((option) => option.mentionables)

  const onMenuOpen = useCallback(
    (popoverRef) => {
      setTimeout(() => {
        if (popoverRef?.current) {
          const anchorRect = popoverRef.current.getBoundingClientRect()
          if (raiseMenuHeight) {
            const spaceAbove = anchorRect.top
            // Determine if the popoverRef is far enough from the top
            const isFarEnoughFromTop = spaceAbove > 0
            setRaiseMenuHeight(isFarEnoughFromTop)
          } else {
            const spaceBelow = window.innerHeight - anchorRect.bottom
            // Determine if the popoverRef is too close to the bottom
            const isTooCloseToBottom = spaceBelow < 0
            setRaiseMenuHeight(isTooCloseToBottom)
          }
        }
      }, 10)
    },
    [raiseMenuHeight]
  )

  const popoverRef = useRef<HTMLUListElement>(null)

  const MentionsPluginSearchBodySkeleton = () => {
    const rowHeight = menuFilter === "Events" || menuFilter === "Content" ? 48 : 32
    return (
      <div className={classes.loadingSkeletonContainer}>
        <Skeleton
          variant={"rect"}
          height={rowHeight}
          width={"100%"}
          className={classes.loadingSkeleton}
        />
        <Skeleton
          variant={"rect"}
          height={rowHeight}
          width={"100%"}
          className={classes.loadingSkeleton}
        />
        <Skeleton
          variant={"rect"}
          height={rowHeight}
          width={"100%"}
          className={classes.loadingSkeleton}
        />
        <Skeleton
          variant={"rect"}
          height={rowHeight}
          width={"100%"}
          className={classes.loadingSkeleton}
        />
        <Skeleton
          variant={"rect"}
          height={rowHeight}
          width={"100%"}
          className={classes.loadingSkeleton}
        />
      </div>
    )
  }

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForMentionMatch}
      anchorClassName={classes.typeaheadAnchor}
      options={filteredOptions}
      onClose={() => {
        setMenuFilter(null)
      }}
      onOpen={() => {
        onMenuOpen(popoverRef)
      }}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex }
      ) => {
        if (!anchorElementRef.current || menuFilter === null) return null

        const title =
          menuFilter === "Products"
            ? experienceLabel.plural
            : menuFilter === "UsersAndGroups"
            ? null
            : menuFilter

        return ReactDOM.createPortal(
          <ul
            ref={popoverRef}
            data-testid={"MentionSuggestionList.popover"}
            className={classNames(classes.popover, {
              [classes.popoverReposition]: raiseMenuHeight,
            })}
          >
            <div className={classes.popoverBody}>
              {title && (
                <DiscoText
                  color={"text.secondary"}
                  variant={"body-xs-700"}
                  marginLeft={1}
                >
                  {title}
                </DiscoText>
              )}
              {options.map((group) => {
                const isAttachBlockGroup = BLOCK_MENTION_TYPES.includes(group.title)
                const isFilteredByGroup = menuFilter === group.title

                if (isAttachBlockGroup && !isFilteredByGroup) return null

                if (group.mentionables.length === 0) return null

                return (
                  <Fragment key={group.title}>
                    {menuFilter === "UsersAndGroups" && (
                      <DiscoText
                        color={"text.secondary"}
                        variant={"body-xs-500"}
                        marginLeft={1}
                      >
                        {group.title}
                      </DiscoText>
                    )}
                    {group.mentionables.map((option) => {
                      const index = filteredOptions.indexOf(option)

                      return (
                        <MentionsTypeaheadMenuItem
                          key={option.key}
                          index={index}
                          isSelected={selectedIndex === index}
                          onClick={() => {
                            setHighlightedIndex(index)
                            selectOptionAndCleanUp(option)
                          }}
                          onMouseEnter={() => {
                            setHighlightedIndex(index)
                          }}
                          option={option}
                        />
                      )
                    })}
                  </Fragment>
                )
              })}

              {filteredOptions.length === 0 &&
                (isLoading || !hasSearched ? (
                  <MentionsPluginSearchBodySkeleton />
                ) : (
                  <DiscoText
                    variant={"body-sm-500"}
                    color={"text.secondary"}
                    margin={0.75}
                    marginLeft={3}
                  >
                    {"No results"}
                  </DiscoText>
                ))}
            </div>

            {menuFilter && hasAttachBlockMention && (
              <div className={classes.menuFooter}>
                {isMobile ? (
                  hasUserMention ? (
                    <div className={classes.mobileFooterMessage}>
                      <DiscoText variant={"body-xs"} color={"text.secondary"}>
                        {`@ for ${memberLabel.plural.toLowerCase()}, @@ for content,`}
                      </DiscoText>
                      <DiscoText variant={"body-xs"} color={"text.secondary"}>
                        {`@@@ for events, @@@@ for ${experienceLabel.plural.toLowerCase()} or ${pathwayLabel.plural.toLowerCase()}`}
                      </DiscoText>
                    </div>
                  ) : (
                    <div className={classes.mobileFooterMessage}>
                      <DiscoText variant={"body-xs"} color={"text.secondary"}>
                        {`@@ for content, @@@ for events,`}
                      </DiscoText>
                      <DiscoText variant={"body-xs"} color={"text.secondary"}>
                        {`@@@@ for ${experienceLabel.plural.toLowerCase()} or ${pathwayLabel.plural.toLowerCase()}`}
                      </DiscoText>
                    </div>
                  )
                ) : hasUserMention ? (
                  <DiscoText variant={"body-xs"} color={"text.secondary"}>
                    {`Type @ for ${memberLabel.plural.toLowerCase()}, @@ for content, @@@ for events, @@@@ for ${experienceLabel.plural.toLowerCase()} or ${pathwayLabel.plural.toLowerCase()}`}
                  </DiscoText>
                ) : (
                  <DiscoText variant={"body-xs"} color={"text.secondary"}>
                    {`Type @@ for content, @@@ for events, @@@@ for ${experienceLabel.plural.toLowerCase()} or ${pathwayLabel.plural.toLowerCase()}`}
                  </DiscoText>
                )}
              </div>
            )}
          </ul>,
          anchorElementRef.current
        )
      }}
    />
  )
}

type PopoverStyleProps = {
  isWebView: boolean
}

const usePopoverStyles = makeUseStyles((theme) => ({
  typeaheadAnchor: {
    zIndex: 100,
  },
  popover: {
    width: "500px",
    border: `1px solid ${theme.palette.groovy.neutral[300]}`,
    background: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.raisedBoxShadow,
    borderRadius: theme.measure.borderRadius.medium,
    padding: theme.spacing(0.5, 0, 0, 0),
    marginTop: theme.spacing(3.5),
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",

    "&::-webkit-scrollbar": {
      display: "none",
    },

    [theme.breakpoints.down("sm")]: {
      minWidth: "320px",
      maxWidth: "90%",
    },
  },
  popoverBody: {
    maxHeight: ({ isWebView }: PopoverStyleProps) => (isWebView ? "190px" : "250px"),
    overflowY: "scroll",
    overflowX: "hidden",
  },
  popoverReposition: {
    bottom: `24px !important`,
    position: "absolute",
  },
  text: {
    paddingLeft: theme.spacing(1),
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    overflow: "hidden",
  },
  daySquare: {
    minWidth: "40px",
    width: "40px",
    height: "40px",
  },
  squareMonthClassName: {
    marginTop: theme.spacing(0.25),
    fontSize: "10px",
    lineHeight: "10px",
  },
  squareDayClassName: {
    marginTop: 0,
    fontSize: "14px",
    lineHeight: "14px",
  },
  customImageContainer: {
    width: "40px",
    height: "40px",
    minWidth: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.default,
  },
  menuFooter: {
    padding: theme.spacing(1),
  },
  mobileFooterMessage: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  loadingSkeletonContainer: {
    padding: theme.spacing(0.5),
    gap: theme.spacing(0.5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingSkeleton: {
    borderRadius: theme.measure.borderRadius.default,
  },
}))

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment MentionsPlugin_Mentionable on Mentionable {
    __typename
    ... on User {
      id
      firstName
      lastName
      fullName
      ...ProfileAvatarFragment
    }
    ... on MemberGroup {
      id
      name
      kind
      role
      visibility
      ...MemberGroupTagFragment
      memberGroupMemberships {
        totalCount
      }
    }
    ... on Product {
      id
      name
      type
      cover
      type
      badge {
        ...BadgeFragment
      }
    }
    ... on Occurrence {
      id
      occurrenceName: name
      datetimeRange
      occurrenceProduct: product {
        type
        occurrenceProductName: name
      }
    }
    ... on ContentUsage {
      id
      entity
      viewerPermissions
      content {
        ...ContentThumbnail_ContentFragment
        name
        id
      }
      product {
        name
      }
      productApp {
        kind
        customAppTitle
      }
      module {
        name
      }
      ...ContentUsageUtils_useContentUsageLocationLabelContentUsageFragment
    }
  }
`
