/**
 * @generated SignedSource<<a016312d8556c15264bf18c1cc1760a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useViewerCanCreateEventsFragment$data = {
  readonly adminMembershipsWithEventApps: {
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "useViewerCanCreateEventsFragment";
};
export type useViewerCanCreateEventsFragment$key = {
  readonly " $data"?: useViewerCanCreateEventsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useViewerCanCreateEventsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useViewerCanCreateEventsFragment",
  "selections": [
    {
      "alias": "adminMembershipsWithEventApps",
      "args": [
        {
          "kind": "Literal",
          "name": "appKind",
          "value": "events"
        },
        {
          "kind": "Literal",
          "name": "productType",
          "value": "course"
        },
        {
          "kind": "Literal",
          "name": "roles",
          "value": [
            "manager",
            "instructor"
          ]
        }
      ],
      "concreteType": "ProductMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "productMemberships",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "productMemberships(appKind:\"events\",productType:\"course\",roles:[\"manager\",\"instructor\"])"
    }
  ],
  "type": "OrganizationMembership",
  "abstractKey": null
};

(node as any).hash = "b84672aa316d33fa636bc058c824aa36";

export default node;
