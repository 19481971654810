import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useUnsavedChangesModalContext } from "@/core/context/UnsavedChangesModalProvider"
import FormStore from "@/core/form/store/FormStore"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import TestUserFormFields, {
  TestUserFormFieldsSkeleton,
} from "@components/test-user/TestUserFormFields"
import { CreateTestUserFormQuery } from "@components/test-user/__generated__/CreateTestUserFormQuery.graphql"
import {
  CreateTestUserInput,
  CreateTestUserModalMutation,
} from "@components/test-user/__generated__/CreateTestUserModalMutation.graphql"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { useLazyLoadQuery } from "react-relay"
import { useHistory, useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"

interface Props {
  form: FormStore<CreateTestUserInput, CreateTestUserModalMutation>
  onClose: VoidFunction
}

function CreateTestUserForm({ form, onClose }: Props) {
  const activeOrganization = useActiveOrganization()!
  const { setUnsavedChanges } = useUnsavedChangesModalContext()
  const history = useHistory()
  const location = useLocation()

  const { node } = useLazyLoadQuery<CreateTestUserFormQuery>(
    graphql`
      query CreateTestUserFormQuery($id: ID!) {
        node(id: $id) {
          ... on Organization {
            __typename
            id
            ...TestUserFormFieldsFragment
          }
        }
      }
    `,
    { id: activeOrganization.id },
    { fetchPolicy: "network-only" }
  )

  const organization = Relay.narrowNodeType(node, "Organization")
  if (!organization) return null

  return (
    <Form id={"CreateTestUserForm"} testid={"CreateTestUserForm"} onSubmit={handleSubmit}>
      <TestUserFormFields organizationKey={organization} form={form} />
    </Form>
  )

  function handleSubmit() {
    // Close modal
    setUnsavedChanges(false)

    onClose()

    // Redirect to the CreateTestUserPage
    history.push(ROUTE_NAMES.CREATE_TEST_USER, {
      createTestUserPageData: {
        formState: toJS(form.state),
        from: location,
      },
    })
  }
}

export default Relay.withSkeleton({
  component: observer(CreateTestUserForm),
  skeleton: () => <TestUserFormFieldsSkeleton />,
})
