/**
 * @generated SignedSource<<b5f1662886ec09484248f17f9e329d46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type FinishMultipartUploadInput = {
  parts: ReadonlyArray<FinishMultipartUploadPart>;
  uploadId: string;
};
export type FinishMultipartUploadPart = {
  eTag: string;
  partNumber: number;
};
export type useMultipartUploadMediaToS3FinishAssetMutation$variables = {
  input: FinishMultipartUploadInput;
};
export type useMultipartUploadMediaToS3FinishAssetMutation$data = {
  readonly finishAssetUpload: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly id: string;
      readonly sizeBytes: number;
      readonly url: string;
    } | null;
  };
};
export type useMultipartUploadMediaToS3FinishAssetMutation = {
  response: useMultipartUploadMediaToS3FinishAssetMutation$data;
  variables: useMultipartUploadMediaToS3FinishAssetMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FinishAssetUploadResponse",
    "kind": "LinkedField",
    "name": "finishAssetUpload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Asset",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sizeBytes",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ValidationError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useMultipartUploadMediaToS3FinishAssetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useMultipartUploadMediaToS3FinishAssetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0a6d29d8a123e9dcd03f0b8143cd9089",
    "id": null,
    "metadata": {},
    "name": "useMultipartUploadMediaToS3FinishAssetMutation",
    "operationKind": "mutation",
    "text": "mutation useMultipartUploadMediaToS3FinishAssetMutation(\n  $input: FinishMultipartUploadInput!\n) {\n  finishAssetUpload(input: $input) {\n    node {\n      id\n      url\n      sizeBytes\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "20d09bdd74271d4a3f5b7a7dd688ab68";

export default node;
