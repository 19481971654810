/**
 * @generated SignedSource<<f9650e2d79f79c781720a49566e09a54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContentEmojiButtonTooltipFragment$data = {
  readonly id: string;
  readonly user: {
    readonly firstName: string | null;
    readonly id: string;
    readonly lastName: string | null;
  };
  readonly " $fragmentType": "ContentEmojiButtonTooltipFragment";
};
export type ContentEmojiButtonTooltipFragment$key = {
  readonly " $data"?: ContentEmojiButtonTooltipFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentEmojiButtonTooltipFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentEmojiButtonTooltipFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ContentLike",
  "abstractKey": null
};
})();

(node as any).hash = "7a550dc7ac71194ba07623ac84a7fa9e";

export default node;
