import CreateTestUserModal from "@components/test-user/CreateTestUserModal"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import { TestIDProps } from "@utils/typeUtils"

export interface CreateTestUserButtonProps
  extends TestIDProps,
    Pick<OverridableDiscoButtonProps, "leftIcon"> {
  className?: string
  children: OverridableDiscoButtonChildren
}

function CreateTestUserButton({
  testid = "CreateTestUserButton",
  className,
  children,
  ...rest
}: CreateTestUserButtonProps) {
  return (
    <OverridableDiscoButton
      className={className}
      testid={testid}
      modal={({ isOpen, onClose }) => {
        // Reset form state on each open
        if (!isOpen) return null
        return <CreateTestUserModal isOpen={isOpen} onClose={onClose} />
      }}
      {...rest}
    >
      {children}
    </OverridableDiscoButton>
  )
}

export default CreateTestUserButton
