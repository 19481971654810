import { useAuthUser } from "@/core/context/AuthUserContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import BookIcon from "@/core/ui/iconsax/linear/book-1.svg"
import BoxIcon from "@/core/ui/iconsax/linear/box-1.svg"
import CalendarIcon from "@/core/ui/iconsax/linear/calendar.svg"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { useOrganizationRoleLabels, useProductRoleLabels } from "@/role/roleUtils"
import ProfilePopover from "@/user/common/profile-popover/ProfilePopover"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import AttachBlockPopover from "@components/editor/plugins/attach-block/AttachBlockPopover"
import { useEditorMentions } from "@components/editor/plugins/mentions/EditorMentionsProvider"
import { EditorMentionFragment$data } from "@components/editor/plugins/mentions/__generated__/EditorMentionFragment.graphql"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import useDisclosure from "@utils/hook/useDisclosure"
import { $getNodeByKey, NodeKey } from "lexical"
import { useEffect, useRef } from "react"
import { graphql } from "relay-runtime"

export type EditorMentionData = Pick<EditorMentionFragment$data, "id"> &
  Partial<
    Pick<
      EditorMentionFragment$data,
      | "user"
      | "memberGroup"
      | "mentionedProduct"
      | "mentionedContentUsage"
      | "mentionedOccurrence"
    >
  >

interface EditorMentionProps {
  nodeKey: NodeKey
  mentionId: GlobalID
  mentionableId: GlobalID
}

function EditorMention({ nodeKey, mentionId, mentionableId }: EditorMentionProps) {
  const { authUser } = useAuthUser()
  const { mentions } = useEditorMentions()
  const experienceLabel = useLabel("admin_experience")
  const drawer = useGlobalDrawer("memberGroup")

  const ref = useRef<HTMLSpanElement>(null)

  const [editor] = useLexicalComposerContext()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const orgRoleLabels = useOrganizationRoleLabels()
  const productRoleLabels = useProductRoleLabels()

  // Find the mention data for this mention.
  const mention = mentions.find((m) => m.id === mentionId)

  const isActiveUser = authUser?.id === mention?.user?.id

  const classes = useStyles({ isActiveUser })

  useEffect(() => {
    if (mention) return
    // If mention is not stored in context, remove the node.
    // This would be the case if mentions are pasted, in this case
    // we have the mentionId and mentionableId but not the mention data.
    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      if (!node) return
      node.remove()
    })
  }, [editor, nodeKey, mention])

  const { type } = Relay.fromGlobalId(mentionableId)

  return (
    <>
      <span
        ref={ref}
        role={"button"}
        tabIndex={0}
        onKeyDown={onOpen}
        onClick={handleOpen}
        className={classes.mention}
      >
        {getText()}
      </span>
      {getPopover()}
    </>
  )

  function getText() {
    if (!mention) return null
    switch (type) {
      case "User":
        if (!mention.user?.fullName) return "@Deleted User"
        return `@${mention.user.fullName}`
      case "MemberGroup":
        if (!mention.memberGroup?.name) return "@Deleted Group"
        return `@${getMemberGroupName(mention.memberGroup)}`
      case "Product":
        return (
          <span>
            <span className={classes.mentionIcon}>
              <BoxIcon height={20} width={20} />
            </span>
            {mention.mentionedProduct?.name
              ? ` ${mention.mentionedProduct.name}`
              : `Deleted ${experienceLabel.singular}`}
          </span>
        )

      case "ContentUsage":
        return (
          <span>
            <span className={classes.mentionIcon}>
              <BookIcon height={20} width={20} />
            </span>
            {mention.mentionedContentUsage?.content.name
              ? ` ${mention.mentionedContentUsage.content.name}`
              : "Deleted Content"}
          </span>
        )
      case "Occurrence":
        return (
          <span>
            <span className={classes.mentionIcon}>
              <CalendarIcon height={20} width={20} />
            </span>
            {mention.mentionedOccurrence?.name
              ? ` ${mention.mentionedOccurrence.name}`
              : "Deleted Event"}
          </span>
        )
    }

    return null
  }

  function getPopover() {
    if (mention?.user) {
      return (
        <ProfilePopover
          userId={mention.user.id}
          anchorEl={ref.current}
          open={isOpen}
          onClose={handleClose}
        />
      )
    }

    const entityId =
      mention?.mentionedProduct?.id ||
      mention?.mentionedOccurrence?.id ||
      mention?.mentionedContentUsage?.id

    if (!entityId) return null
    return (
      <AttachBlockPopover
        entityId={entityId}
        anchorEl={ref.current}
        open={isOpen}
        onClose={handleClose}
      />
    )
  }

  function getMemberGroupName(
    memberGroup: NonNullable<EditorMentionData["memberGroup"]>
  ) {
    // Role member groups display role label instead of their name
    switch (memberGroup.role) {
      case "owner":
      case "admin":
        return `Community ${orgRoleLabels[memberGroup.role].plural}`
      case "manager":
      case "instructor":
        return productRoleLabels[memberGroup.role].plural
    }
    return memberGroup.name
  }
  function handleOpen(e: React.MouseEvent<Element, MouseEvent>) {
    if (mention?.memberGroup) {
      drawer.open({ memberGroupId: mention.memberGroup.id, tab: "details" })
    } else {
      onOpen()
    }
    e.stopPropagation()
  }
  function handleClose(e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) {
    onClose()
    if (e) e.stopPropagation()
  }
}

type StyleProps = {
  isActiveUser: boolean
}

const useStyles = makeUseStyles((theme) => ({
  mention: ({ isActiveUser }: StyleProps) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: "1px 2px",
    borderRadius: theme.measure.borderRadius.default,
    cursor: "pointer",

    ...styleIf(isActiveUser, {
      color: theme.palette.orange.main,
      backgroundColor: theme.palette.orange.light,
    }),
  }),
  mentionIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    padding: "3px 2px 0 2px",
  },
}))

export default EditorMention

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment EditorMentionFragment on ContentMention {
    id
    user {
      id
      fullName
    }
    memberGroup {
      id
      name
      kind
      role
    }
    mentionedProduct {
      id
      name
    }
    mentionedContentUsage {
      id
      content {
        id
        name
      }
    }
    mentionedOccurrence {
      id
      name
      content {
        name
      }
    }
  }
`
// everything needed to display in editor - add ids for new
