import { useOnboardingChecklistContext } from "@/core/context/OnboardingChecklistContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { LocationState } from "@/core/route/util/routeUtils"
import { startImpersonatingTestUser } from "@/product/util/hook/useInitImpersonateTestUser"
import FullScreenLoadingPageWithMessage, {
  DEFAULT_FULL_SCREEN_LOADING_PAGE_REDIRECT_ROUTE,
} from "@components/loading-page/FullScreenLoadingPageWithMessage"
import { CreateTestUserInput } from "@components/test-user/__generated__/CreateTestUserModalMutation.graphql"
import { CreateTestUserPageMutation } from "@components/test-user/__generated__/CreateTestUserPageMutation.graphql"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { observer } from "mobx-react-lite"
import { graphql } from "react-relay"
import { useHistory } from "react-router-dom"

function CreateTestUserPage() {
  const { completeOrIgnoreChecklistItem } = useOnboardingChecklistContext()
  const history = useHistory()

  const form = useFormStore<CreateTestUserPageMutation, CreateTestUserInput>(
    graphql`
      mutation CreateTestUserPageMutation($input: CreateTestUserInput!) {
        response: createTestUser(input: $input) {
          node {
            key
            testUser {
              fullName
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      firstName: "",
      lastName: "",
      membershipPlanId: "",
      organizationMemberGroupIds: [],
      products: [],
    }
  )

  return (
    <FullScreenLoadingPageWithMessage
      title={"Creating Test Member"}
      subtitle={
        "We are creating the test member. This process can take a while, hang in there..."
      }
      onLoad={handleSubmit}
    />
  )

  async function handleSubmit(locationState: LocationState) {
    if (!locationState?.createTestUserPageData) {
      return history.replace(DEFAULT_FULL_SCREEN_LOADING_PAGE_REDIRECT_ROUTE)
    }

    const { formState } = locationState.createTestUserPageData
    const { didSave, response } = await form.submit(formState, {
      // Invalidate the store so when we get redirected back to the original page,
      // all the data will be refetched so we can ensure everything is up to date
      updater: (store) => store.invalidateStore(),
    })

    if (didSave && response?.node) {
      await completeOrIgnoreChecklistItem("create_test_member")

      displaySuccessToast({
        testid: "CreateTestUserForm.success-toast",
        message: "Test Member successfully created",
        body: `Test member "${response.node.testUser.fullName}" has been successfully created. You can start switching to this test member to experience the community.`,
        buttonConfig: {
          buttonLabel: "Switch to Test Member",
          onClick: () => startImpersonatingTestUser(response.node!.key),
        },
        timeout: 60 * 1000, // 60 seconds
      })
    }

    redirect(locationState)
  }

  // Send back to where they came from OR the community home
  function redirect(locationState: LocationState) {
    if (locationState?.createTestUserPageData) {
      history.replace(locationState.createTestUserPageData.from)
    } else {
      history.replace(DEFAULT_FULL_SCREEN_LOADING_PAGE_REDIRECT_ROUTE)
    }
  }
}

export default observer(CreateTestUserPage)
