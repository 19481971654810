import { useLabels } from "@/core/context/LabelsContext"
import UnsavedChangesModalProvider, {
  useInitUnsavedChangesModalContext,
} from "@/core/context/UnsavedChangesModalProvider"
import { useFormStore } from "@/core/form/store/FormStore"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CreateTestUserForm from "@components/test-user/CreateTestUserForm"
import {
  CreateTestUserInput,
  CreateTestUserModalMutation,
} from "@components/test-user/__generated__/CreateTestUserModalMutation.graphql"
import { DiscoButton, DiscoModal } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { graphql } from "relay-runtime"

type Props = {
  isOpen: boolean
  onClose: VoidFunction
}

function CreateTestUserModal({ isOpen, onClose: forceClose }: Props) {
  const unsavedChangesModal = useInitUnsavedChangesModalContext()

  const classes = useStyles()
  const labels = useLabels()

  const form = useFormStore<CreateTestUserModalMutation, CreateTestUserInput>(
    graphql`
      mutation CreateTestUserModalMutation($input: CreateTestUserInput!) {
        response: createTestUser(input: $input) {
          errors {
            field
            message
          }
        }
      }
    `,
    {
      firstName: "",
      lastName: "",
      membershipPlanId: "",
      organizationMemberGroupIds: [],
      products: [],
    }
  )

  useEffect(() => {
    unsavedChangesModal.setUnsavedChanges(form.isChanged)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.isChanged])

  return (
    <UnsavedChangesModalProvider {...unsavedChangesModal}>
      <DiscoModal
        shouldCloseOnOverlayClick={false}
        isOpen={isOpen}
        onClose={handleClose}
        classes={{ body: classes.body }}
        title={"New Test Member"}
        subtitle={`Test members allow you to test the ${labels.admin_member.singular} experience in the community.`}
        modalContentLabel={"Create test member"}
        width={"720px"}
        shouldCloseOnEsc={false}
        testid={"CreateTestUserModal"}
        body={<CreateTestUserForm form={form} onClose={forceClose} />}
        fullWidthButtons
        buttons={
          <div className={classes.buttonsContainer}>
            <DiscoButton
              key={"cancel"}
              color={"grey"}
              variant={"outlined"}
              testid={"CreateTestUserModal.button.cancel"}
              onClick={handleClose}
            >
              {"Cancel"}
            </DiscoButton>
            <DiscoButton
              key={"confirm"}
              testid={"CreateTestUserModal.button.submit"}
              type={"submit"}
              form={"CreateTestUserForm"}
              shouldDisplaySpinner={form.isSubmitting}
              disabled={
                form.isSubmitting ||
                !form.state.firstName ||
                !form.state.lastName ||
                !form.state.membershipPlanId
              }
            >
              {"Create Test Member"}
            </DiscoButton>
          </div>
        }
      />
    </UnsavedChangesModalProvider>
  )

  function handleClose() {
    unsavedChangesModal.handleLeave({
      onLeave: forceClose,
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  body: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5, 1, 1),
    },
  },
  buttonsContainer: {
    display: "flex",
    gap: theme.spacing(1),
  },
}))

export default Relay.withSkeleton<Props>({
  component: observer(CreateTestUserModal),
  skeleton: () => null,
})
