import ROUTE_NAMES from "@/core/route/util/routeNames"
import { DeleteTestUserButtonFragment$key } from "@components/test-user/__generated__/DeleteTestUserButtonFragment.graphql"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
  OverridableDiscoButtonProps,
} from "@disco-ui/button/OverridableDiscoButton"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"
import { useHistory, useLocation } from "react-router-dom"

export interface DeleteTestUserButtonProps
  extends TestIDProps,
    Pick<OverridableDiscoButtonProps, "leftIcon"> {
  testUserKey: DeleteTestUserButtonFragment$key
  className?: string
  children: OverridableDiscoButtonChildren
}

function DeleteTestUserButton({
  testid = "DeleteTestUserButton",
  testUserKey,
  className,
  children,
  ...rest
}: DeleteTestUserButtonProps) {
  const history = useHistory()
  const location = useLocation()

  const testUser = useFragment<DeleteTestUserButtonFragment$key>(
    graphql`
      fragment DeleteTestUserButtonFragment on User {
        id
        fullName
      }
    `,
    testUserKey
  )

  return (
    <OverridableDiscoButton
      className={className}
      testid={testid}
      modal={({ isOpen, onClose }) => {
        // Reset form state on each open
        if (!isOpen) return null
        return (
          <DiscoWarningModal
            modalContentLabel={"Delete test member Dialog"}
            testid={"DeleteTestUserButton.delete-warning-modal"}
            isOpen={isOpen}
            onClose={onClose}
            title={"Delete Test Member?"}
            description={`Deleting "${testUser.fullName}" will delete all data associated with this test member. This is a permanent action and cannot be undone.`}
            confirmationButtonProps={{
              onClick: () => {
                // Close the modal
                onClose()

                history.push(ROUTE_NAMES.DELETE_TEST_USER, {
                  deleteTestUserPageData: {
                    testUserId: testUser.id,
                    from: location,
                  },
                })
              },
              children: "Yes, delete it",
            }}
          />
        )
      }}
      {...rest}
    >
      {children}
    </OverridableDiscoButton>
  )
}

export default DeleteTestUserButton
