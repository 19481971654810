import makeUseStyles from "@assets/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import { isMoreContent } from "@components/scroll-shadow/showShadowUtils"
import { darken } from "@material-ui/core"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import useOnWindowResize from "@utils/hook/useOnWindowResize"
import useResizeObserver from "@utils/hook/useResizeObserver"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import React, { createContext, useCallback, useEffect, useRef, useState } from "react"

interface Props extends TestIDProps, StyleProps {
  classes?: Partial<ClassNameMap<"scrollContainer" | "parentContainer">>
  hideScrollbar?: boolean
  disableScroll?: boolean
  shouldShowDivider?: boolean
}

export const ScrollContext = createContext<{
  scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>
}>({
  scrollContainerRef: { current: null },
})

const ScrollShadowContainer: React.FC<Props> = ({
  children,
  testid = "ScrollShadowContainer",
  height,
  classes: customClasses,
  hideScrollbar = false,
  disableScroll,
  shouldShowDivider = false,
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const parentRef = useRef<HTMLDivElement | null>(null)
  const [isScrolled, setIsScrolled] = useState(false)
  const [shouldShowFooterShadow, setShouldShowFooterShadow] = useState<boolean>(false)

  const handleShowShadows = useCallback(() => {
    if (ref.current) setIsScrolled(Boolean(ref.current.scrollTop))
    setShouldShowFooterShadow(isMoreContent(ref))
  }, [])

  const handleRef = useCallback(
    (e: HTMLDivElement | null) => {
      ref.current = e
      if (e) handleShowShadows()
    },
    [handleShowShadows]
  )

  useOnWindowResize(handleShowShadows)
  // if the height of the container changes, we need to recheck if we should show the footer shadow
  // ensures the footer shadow shows before first scroll
  useResizeObserver(ref, handleShowShadows)

  const classes = useStyles({ height, hideScrollbar, disableScroll, shouldShowDivider })

  // Prevent parent from being programatically scrolled sometimes by .scrollIntoView()
  useEffect(() => {
    if (!parentRef.current) return
    const el = parentRef.current
    const onScroll = () => {
      if (el.scrollTop > 0) el.scrollTop = 0
    }
    el.addEventListener("scroll", onScroll)
    return () => el.removeEventListener("scroll", onScroll)
  }, [])

  const showFooterShadow = !disableScroll && shouldShowFooterShadow

  return (
    <div
      ref={parentRef}
      className={classNames(classes.parentContainer, customClasses?.parentContainer)}
      data-testid={testid}
    >
      <div
        className={classNames(classes.headerEl, {
          [classes.headerShadow]: isScrolled,
        })}
      />
      <div
        ref={handleRef}
        data-testid={`${testid}.scroll-container`}
        className={classNames(classes.scrollContainer, customClasses?.scrollContainer)}
        onScroll={handleShowShadows}
      >
        <ScrollContext.Provider value={{ scrollContainerRef: ref }}>
          {children}
        </ScrollContext.Provider>
      </div>
      <div
        className={classNames(classes.footerEl, {
          [classes.footerShadow]: showFooterShadow,
        })}
      />
    </div>
  )
}

interface StyleProps {
  height?: React.CSSProperties["height"]
  hideScrollbar?: boolean
  disableScroll?: boolean
  shouldShowDivider?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  parentContainer: ({ height, disableScroll }: StyleProps) => ({
    position: "relative",
    height: height || "100%",
    // overflow must be hidden for boxShadows
    overflow: "hidden",

    ...styleIf(disableScroll, {
      overflow: "unset",
    }),
  }),
  scrollContainer: ({ height, hideScrollbar, disableScroll }: StyleProps) => ({
    height: height || "100%",
    overflow: "hidden auto",

    ...styleIf(hideScrollbar, {
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "-ms-overflow-style": "none",
      scrollbarWidth: "none",
    }),

    ...styleIf(disableScroll, {
      overflow: "unset",
    }),
  }),
  headerEl: {
    width: "100%",
    // for the box shadow to be visible, it must have at least the height of the shadow we're showing
    // set parent container to hide overflow so top and bottom shadows outside parent are not visible
    height: `${SHADOW_BLUR}px`,
    top: `-${SHADOW_BLUR}px`,
    position: "absolute",
    zIndex: theme.zIndex.raise3,
    pointerEvents: "none",
  },
  footerEl: {
    width: "100%",
    // for the box shadow to be visible, it must have at least the height of the shadow we're showing
    // set parent container to hide overflow so top and bottom shadows outside parent are not visible
    height: `${SHADOW_BLUR}px`,
    bottom: `-${SHADOW_BLUR}px`,
    position: "absolute",
    zIndex: theme.zIndex.raise3,
    pointerEvents: "none",
  },
  headerShadow: {
    // first shadow is for the border, second is for the blur
    boxShadow: ({ shouldShowDivider }: StyleProps) =>
      `${
        shouldShowDivider ? `0px 2px 0px ${theme.palette.divider},` : ""
      } 0px 5px ${SHADOW_BLUR}px ${
        theme.palette.type === "dark"
          ? darken(theme.palette.background.paper, 0.55) // CustomThemeProvider goovyDepths.insideCard is origin of this color formula
          : "rgba(46, 64, 88, 0.12)"
      }`,
  },
  footerShadow: {
    // first shadow is for the border, second is for the blur
    boxShadow: ({ shouldShowDivider }: StyleProps) =>
      `${
        shouldShowDivider ? `0px -2px 0px ${theme.palette.divider},` : ""
      } 0px -5px ${SHADOW_BLUR}px ${
        theme.palette.type === "dark"
          ? darken(theme.palette.background.paper, 0.55) // CustomThemeProvider goovyDepths.insideCard is origin of this color formula
          : "rgba(46, 64, 88, 0.12)"
      }`,
  },
}))
const SHADOW_BLUR = 16
export default ScrollShadowContainer
